///var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/css/EventDialogServiceTimeSidebar.scss

.custom-bottom-sidebar {
  width: 100% !important;
  height: fit-content;
  flex-direction: column;
  position: fixed; // Ensure it stays at the bottom
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  .p-sidebar-header{
    padding: unset;
  }
  
  .p-sidebar-content {
    transition: all 0.3s ease-in-out;
    overflow: hidden; // Hide scrollbar
    flex-grow: 1; /* Make content area flexible */
    padding: 1px 5px 2px 5px;
  }

  .p-sidebar-close {
    display: none; // Hide the close icon
  }

  .sidebar-content { 
    flex-direction: column;
    height: 100%;
    padding: 5px 1px 0px 1px;
    flex-grow: 1; // Make content area flexible
    overflow-y: clip; // Allow vertical scrolling


    .cumulative-total-container {
      background: #efefef;      
      flex-direction: column;
      justify-content: center;
    }  
    
    .bank-details,
    .qr-code {
      text-align: left;
      line-height: 1.2;
    
      p.inline-text {
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    .qr-code {
      // Center the QR code image
      .qrcode.react {
        display: block;
        margin: 0 auto;
      }
    }
    
    
    .credit-card-input-container {
      overflow-wrap: break-word;
      white-space: normal;
      height: 240px;
      overflow: overlay;

      

      .bank-details,
      .qr-code {
        p.inline-text {
          display: block; // Change display to block to handle overflow
          margin: 0;
          padding: 2px 0; // Reduce spacing between lines
        }
      }

      .credit-card-inputs {       
        flex-direction: column;
        width: 100%; // Make inputs full width
      }
    }

    .payment-InputSwitch {      
      flex-direction: column;
      justify-content: center;
      height: 150px; // Fixed height for the payment InputSwitch container
      overflow: hidden; // Prevent auto height adjustment
    }

    .payment-method-container {
      background: #f7f7f7;
      box-shadow: 1px 3px 3px 0px rgb(156 156 156 / 32%);
      
      flex-direction: column;
      justify-content: center;
      
      .input-switch-wrapper {
        
        justify-content: center;
        width: auto;
        margin-bottom: 10px;
      }
    }
    

    .text-center {      
      justify-content: center;
      align-items: center;
      height: auto;
    }

    .cumulative-total {
      font-size: 1.5rem; // Increase font size
      background: #efefef; // Background color for cumulative total
      padding: 7px;
      box-shadow: 1px 3px 3px 0px rgb(156 156 156 / 32%);
    }

    .full-submit-button {
      width: 100%;
      height: 100%;
      background-color: #17f081;
      border-color: #17f081;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: #0fa761;
      }

      
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;        
    }

    .confirm-button-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .confirm-text {
      font-weight: bold;
      margin-top: 50%;
      color: #1f1f1f;
    }

    .cumulative-total-text {
      margin-top: auto;
      font-weight: bold;
      color: #585858;
      font-size: larger;
    }

    .p-field-horizontal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px; // Add margin for better spacing
      
      label {
        flex: 1;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px; // Ensure proper spacing

      }

      .p-inputtext,
      .p-inputswitch {
        flex: 0.3;
        text-align: right;
      }
    }

    h4 {
      margin-bottom: 10px;
    }
  }

  .custom-input-switch {
    .p-inputswitch-slider {
      width: 60px; // Adjust the width as needed
    }
    margin-right: 40px; // Ensure proper spacing
  }

  @media (max-width: 1366px) {
    /* Adjust styles for screens up to 1366px wide */
    height: 41%; /* Adjusted height for responsive layout */
  }
  
  @media (min-width: 1367px) and (max-width: 1600px) {
    /* Adjust styles for screens from 1367px to 1600px wide */
    height: fit-content; /* Adjusted height for responsive layout */
  }
  
  @media (min-width: 1701px) and (max-width: 1800px) {
    /* Adjust styles for screens from 1701px to 1800px wide */
    height: fit-content; /* Adjusted height for responsive layout */
  }
}
