.datatable-modal {
    display: flex;
    justify-content: center;
    align-items: center;
  
    .modal-dialog {
      // Allows the modal to grow based on content, up to 50vw
      max-width: 50vw;
      margin: auto; // Centers the modal horizontally
  
      .modal-content {
        // Ensures there's a reasonable minimum width, but you can adjust as needed
        min-width: 300px; // Adjust this value based on your content needs
  
        .modal-body {
          overflow-x: auto; // Allows for horizontal scrolling if content overflows
  
          .datatable {
            min-width: 100%; // Ensures the DataTable fills the modal body
            // Any additional styling to ensure DataTable's responsiveness
          }
        }
      }
    }
  }
  
  .fc-header-toolbar {
    font-size: small;
  } 