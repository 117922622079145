.sidebar {
  width: 250px;
  background: #fff; /* Light background color as per the design */
  color: #333; /* For text color */
  height: 120vh;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.user-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.user-info .user-name {
  font-weight: bold;
  color: #333;
}

.user-info .user-role {
  font-size: 0.8em;
  color: #666;
}

.menu {
  list-style: none; /* Remove list bullets */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.menu-item {
  padding: 10px 15px; /* Adjust padding as necessary */
  color: #9b9797; /* Text color for items */
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.fas {
  margin-right: 10px;
  /* Adjust the size of the icons here if necessary */
}

.menu-item:hover, .menu-item.active {
  background-color: #f3f1f1; /* Background color on hover/active */
  border-left: 3px solid #a93232; /* Highlight color for active state */
  color: #a93232;
}


.fas {
  margin-right: 10px; /* Space between icon and text */
}

/* Add this at the end of your Sidebar.css file */
.new-price-option {
  background-color: #e9ecef;
}



.submenu {
  list-style-type: none; /* Remove bullet points */
  padding-left: 20px; /* Optional: Indent sub-items */
}

.submenu-item {
  margin-bottom: 10px; /* Optional: Space between sub-items */
}

