/* /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/css/Dashboard.css */



.dashboard {
  display: flex;
  min-height: 100vh;
}

.dashboard-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.dashboard-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-area {  
  display: flex;
  justify-content: center;
  padding: 20px;
}

.panels {
  flex-grow: 1; /* Adjust the grow factor as needed */
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Or whatever maximum width you prefer */
}

.main-and-calendar {
  flex-grow: 2; /* Adjust the grow factor as needed */
  display: flex;
  flex-direction: column;
}

/* Main Content Wrapper Styles */
.dashboard-content {
  flex-grow: 1;
  background-color: #edf1f7; /* Lighter background for the content */
  color: #333;
  padding: 20px;
}

/* Header Styles */
.header {
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.header-title {
  font-size: 1.5rem;
}

.header-user {
  font-size: 1rem;
}

/* Card Styles */
.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-bottom: 1rem;
  color: #6c757d; /* Grey color for subtitle text */
}

.card-content {
  font-size: 1rem;
}

.pad1{
  padding: 1%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
  }

  .dashboard-content {
    padding: 10px;
  }
  
}
