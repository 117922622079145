/* Custom styles for DataTables */
.custom-datatable .table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff; /* White background for the table */
}

.custom-datatable .table thead th {
  background-color: #007bff; /* Bootstrap primary color for header */
  color: white;
}

.custom-datatable .table th,
.custom-datatable .table td {
  padding: .75rem; /* Bootstrap standard padding */
  vertical-align: top;
  border-top: 1px solid #dee2e6; /* Bootstrap standard border color */
}

/* Styling for the search box and page length select */
.dataTables_wrapper .dataTables_filter {
  text-align: right; /* Align search box to the right */
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: .5em; /* Bootstrap standard margin */
  padding: .375rem .75rem; /* Bootstrap standard padding */
  border: 1px solid #ced4da; /* Bootstrap standard border */
  border-radius: .25rem; /* Bootstrap standard border radius */
}

.dataTables_wrapper .dataTables_length select {
  padding: .375rem .75rem; /* Bootstrap standard padding */
  border: 1px solid #ced4da; /* Bootstrap standard border */
  border-radius: .25rem; /* Bootstrap standard border radius */
}

/* Custom style for pagination */
.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: .375rem .75rem; /* Bootstrap standard padding */
  margin: .2rem; /* Slightly smaller than Bootstrap standard margin */
  border-radius: .25rem; /* Bootstrap standard border radius */
  border: 1px solid transparent; /* Hide border */
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid #ced4da; /* Bootstrap standard border on hover */
}

/* Bootstrap button styles for action buttons */
.custom-datatable .table .btn-primary {
  color: white;
  background-color: #007bff;
  border-color: #007bff;
}

.custom-datatable .table .btn-primary:hover {
  color: white;
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Adjustments for responsive layout */
@media only screen and (max-width: 768px) {
  .custom-datatable .table {
    width: 100%;
    overflow-x: auto; 
  }
}

/* Style the DataTables length and pagination for a rounded appearance */
.dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_paginate .paginate_button {
  border-radius: 0.5rem;
  transition: all 0.3s;
}

/* Hover effect for pagination buttons */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: #e9ecef;
}

.custom-datatable .table th:nth-child(1),
.custom-datatable .table td:nth-child(1) {
  width: 15%;
}
