// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/css/Header.scss

.p-menubar .p-menubar-item .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5em; // Adjusts the space between the icon and label
}

.p-menuitem-link{
    text-decoration: none; // Removes text underline for menubar items
    padding: 10px 15px;
    color: #9b9797;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;

    .fa-icon {
        margin-right: 0.6rem; // Add margin-right for spacing
      }
}

.p-menubar .p-menubar-item  {
    // Align items within the menu item
    display: flex;
    align-items: center;
  
    // FontAwesome icon specific styling
    .fa-icon {
      margin-right: 0.5em; // Add some space between icon and label
    }
  }


  .custom-small-datatable {
    .p-datatable-header {
      font-size: 0.8rem;
      padding: 0.5rem;
      background-color: #f4f4f4;
      color: #333;
      // Add any additional styles for the header here
    }
  
    .p-datatable-tbody > tr > td {
      font-size: 0.75rem;
      padding: 0.5rem;
      // Adjustments to make table cells smaller
    }
  
    .p-column-title {
      font-weight: bold;
    }
  }

  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.5rem;
    font-size: 0.8rem;
    // Smaller header cells
  }