// Inside your .scss file linked to the Booking component
.employee-cell-red {
  background-color: rgb(255, 211, 211);
  color: #5e0000; // Ensuring text is readable on a red background
  font-weight: bold;
}

.employee-row-green {
  background-color: #d4ffd5;
  color: #004401;
}


.spinner-overlay {
  position: fixed; // This will position the overlay relative to the viewport
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
  display: flex;
  justify-content: center; // This will horizontally center the child elements
  align-items: center; // This will vertically center the child elements
  z-index: 9999; // This ensures that the overlay is above other elements
}

.p-datatable .p-datatable-expander {
  display: flex;
  align-items: center;

  .p-badge {
    margin-left: var(--spacing-small);
    // You can replace --spacing-small with an actual value like 0.5rem
  }
}



