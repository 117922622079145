@import '~primeflex/primeflex.scss'; // If using PrimeFlex for layout

.dialogContainer {
  background-color: #f5fff9;
}

.bigButton {
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  
  &:hover {
    background-color: #f0f0f0;
    color: #333;
  }

  &:active {
    background-color: #ddd;
    color: #222;
  }

  display: inline-flex; 
  .p-button-label {
    flex-grow: 1;
  }
  .p-button-icon-left {
    margin-right: 0.5rem;
  }
}

.bgindex1 {
  background-color: #afd0bf;  
}


.bgindex2{
  background: #bcdfcd;
  box-shadow: 1px 2px 12px 0px #7fb89ae3;
}

.chipindex1{
  background: #a4c6b5;
  border-radius: 3px;
  color: #eefff6;
  font-weight: 500;
}


.selectedButton {
  background-color: #05d369;  
  color: #fff;
  border: 1px solid #19f081;

  &:hover {
    background-color: #02b559;
    border: 1px solid #19f081;
  }

  &:active {
    background-color: #05d369;
    border: 1px solid #19f081;
  }
}

.cardHover {
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #f0f0f0;
    color: #333;
  }

  &:active {
    background-color: #ddd;
    color: #222;
  }
}

.p-highlight {  
  background-color: #364e41;
}


.selectButtonCustom {
  width: 100%; // Ensure the button takes full width of the column
  height: 7rem; // Fixed height
  transition: background-color 0.3s, color 0.3s;
  margin: 0.5rem 0; // Add margin for spacing between buttons
  display: flex;
  justify-content: center;
  align-items: normal;
  overflow: overlay;
  background: #b0d0bf;
  border: 1px solid #12d771;
  color: #003900;

  &:active, &:hover, &:focus, &.activeButton {
    background-color: #19f081;
    border: 1px solid #12d771;
  }



  .p-button {
    background-color: #b0d0bf; // Set the background color for the button
    color: #fff; // Set the text color

    &:hover {
      background-color: #60d0b4; // Change background on hover
      color: #333; // Change text color on hover
    }

    &:active {
      background-color: #60d0b4; // Change background on active
      color: #222; // Change text color on active
    }
  }

  &.activeButton .p-button {
    background-color: #d6e7de;
    color: #fff;

    &:hover {
      background-color: #b9dbcf;
    }

    &:active {
      background-color: #a0d1c5;
    }
  }
}

.selectButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem; // Add some spacing between buttons
}

.centeredButtonCol {
  display: flex;
  justify-content: center;
  align-items: center;
}


.customDialog {
  height: 100%;
  width: 80vw;
  top: 5%;
  position: absolute;
  border-radius: 15px; /* Add border-radius */
  z-index: 500;
}

