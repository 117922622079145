.invoice-container {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  
    .invoice-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        color: #333;
      }
    }

    .col-6 {
      text-align: center; // Center text
      display: flex;
      flex-direction: column;
      align-items: center; // Center items horizontally in a flex container
      justify-content: center; // Center items vertically in a flex container
      margin: auto;
  
      .footer-logo {
        margin-bottom: 20px; // Add some space below the logo
      }
  
      .line-index {
        width: 100%; // Ensure the line spans the full width of the column
        margin: 20px 0; // Add some vertical spacing around the line
      }
    }
  
    .invoice-logo {
      max-width: 150px;
      height: auto;
    }
  
    .client-details {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
  
      div {
        margin-right: 15px;
  
        strong {
          display: block;
          margin-bottom: 5px;
          color: #333;
          font-weight: bold;
        }
      }
    }

    .line-index{
      border-top: 1px solid #a4a4a4;
    }

    .text-left{
      text-align: left;
    }

    .bottom-unset{
      margin-bottom: unset;
    }
  
    .invoice-items {
      width: 100%;
      margin-top: 20px;
      border-collapse: collapse;
  
      th, td {
        border: 1px solid #eee;
        padding: 10px;
        text-align: left;
  
      
      }
  
      th {
        background-color: #f5f5f5;
        color: #333;
      }
    }
  
    .invoice-totals {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
  
      div {
        margin-left: 15px;
        text-align: right;
      }
    }
  }
  
  // Bootstrap Grid System
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  
  // PrimeReact DataTable Overrides
  .p-datatable {
    .p-datatable-thead > tr > th {
      background-color: #f5f5f5;
      color: #333;
    }
  
    .p-datatable-tbody > tr > td {
      border: 1px solid #eee;
    }
  
    .p-datatable-tfoot > tr > td {
      background-color: #f5f5f5;
    }
  }
  
  

  
  .footer-logo {
    max-width: 100px; /* Adjust as necessary */
  }

  
  .legal-notes {
    font-size: 0.8em;
  }
  
  .pi { /* Assuming you're using PrimeIcons */
    margin-right: 5px;
  }

  

  .grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
  gap: 20px; /* Spacing between grid items */
}

.totals-section {
  margin-top: 20px;
}

.grid-item {
  background: #f5f5f5; /* Background color for grid items */
  padding: 10px;
  border-radius: 5px; /* Rounded corners for grid items */
}

/* Styles for the labels and values */
.sub-total-label, .tax-label, .total-label {
  font-weight: bold;
}

.footer-section {
  margin-top: 40px;
}

.footer-logo img {
  max-width: 100px;
}

.icon-text {
  display: flex;
  align-items: center;
}

.large-icon {
  font-size: 1.5em; /* Adjust the size as needed */
  margin-right: 0.5em; /* Add some space between the icon and text */
}



