// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/css/Login.scss
  
.background-container {
    background-image: url('https://afaa.website/s/b86cef.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; // Ensure the background is behind all content
  }
  

  @keyframes colorLoop {
    0%, 100% { background-color: rgba(255, 0, 0, 0.143); } // Red
    4.17% { background-color: rgba(255, 127, 0, 0.143); } // Orange
    8.33% { background-color: rgba(255, 255, 0, 0.143); } // Yellow
    12.5% { background-color: rgba(127, 255, 0, 0.143); } // Lime
    16.67% { background-color: rgba(0, 255, 0, 0.143); } // Green
    20.83% { background-color: rgba(0, 255, 127, 0.143); } // Spring Green
    25% { background-color: rgba(0, 255, 255, 0.143); } // Cyan
    29.17% { background-color: rgba(0, 127, 255, 0.143); } // Dodger Blue
    33.33% { background-color: rgba(0, 0, 255, 0.143); } // Blue
    37.5% { background-color: rgba(127, 0, 255, 0.143); } // Purple
    41.67% { background-color: rgba(255, 0, 255, 0.143); } // Magenta
    45.83% { background-color: rgba(255, 0, 127, 0.143); } // Deep Pink
    50% { background-color: rgba(255, 0, 0, 0.143); } // Red
    54.17% { background-color: rgba(255, 127, 0, 0.143); } // Orange
    58.33% { background-color: rgba(255, 255, 0, 0.143); } // Yellow
    62.5% { background-color: rgba(127, 255, 0, 0.143); } // Lime
    66.67% { background-color: rgba(0, 255, 0, 0.143); } // Green
    70.83% { background-color: rgba(0, 255, 127, 0.143); } // Spring Green
    75% { background-color: rgba(0, 255, 255, 0.143); } // Cyan
    79.17% { background-color: rgba(0, 127, 255, 0.143); } // Dodger Blue
    83.33% { background-color: rgba(0, 0, 255, 0.143); } // Blue
    87.5% { background-color: rgba(127, 0, 255, 0.143); } // Purple
    91.67% { background-color: rgba(255, 0, 255, 0.143); } // Magenta
    95.83% { background-color: rgba(255, 0, 127, 0.143); } // Deep Pink
  }

  
  .background-overlay {
    animation: colorLoop 50s linear infinite; // White with opacity
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1; // Ensure the overlay is behind all content
  }

  .background-login{
    background-color: rgb(255 255 255 / 69%) !important;
  }
  
  .footer-text {
    font-size: 0.8rem;
    color: gray;
  }
  
  .footer-text a {
    color: gray;
    text-decoration: none;
  }
  
  .footer-text a:hover {
    text-decoration: underline;
  }