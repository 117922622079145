@keyframes popAnimation {
    0% {
      transform: scale(0.3) translateY(30%);
      opacity: 0;
    }
    50% {
      transform: scale(1.05) translateY(-2%);
      opacity: 0.85;
    }
    100% {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }
  
  .custom-modal-animation {
    animation: popAnimation 0.6s ease-out;
  }
  