.content {
    padding: 40px; /* Increased padding for better spacing */
    background-color: #f4f4f4; /* Subtle background color */
}

.report-title {
    font-size: 2rem;
    color: #191515;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase; /* Make text uppercase */
    letter-spacing: 1.5px; /* Add letter spacing for a more professional look */
}

.chart-col {
    margin-bottom: 30px;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    text-transform: uppercase; /* Make text uppercase */
    margin-left: 20px; /* Add margin-left for better spacing */
    flex: 1 1 45%; /* Flex-grow and flex-basis for responsive design */
    margin: 20px; /* Margin for spacing between charts */
}

h6 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    text-transform: uppercase; /* Make text uppercase */
    color: #3a3a3a; 
    letter-spacing: 1px; /* Add letter spacing */
}

.filter-col {
    margin-bottom: 30px;
}

.custom-dropdown {
    display: inline-flex;
    align-items: center;
    background: #b5bac0;
    padding: 0.5rem;
    border-radius: 20px;
    margin-right: 10px;
    color: #495057;
    border: var(--bs-btn-border-width) solid #a9a9a9;
    width: 100%;
}

.custom-dropdown .p-dropdown-label {
    padding: 0 0.5rem;
}

.custom-dropdown .p-dropdown-trigger {
    display: none;
}

/* Hover and active state */
.custom-dropdown:hover, .custom-dropdown:active, .custom-dropdown:first-child:active,
.custom-dropdown.p-dropdown.p-component.p-filled, .custom-dropdown.selected {
    background: #ffc4c4;
    color: #321111;
    border: 1px solid #ff2626;
}

/* Selected state */
.custom-dropdown.p-dropdown.p-component.p-filled,
.custom-dropdown.p-dropdown.p-component:focus {
    background: #ffc4c4;
    color: #321111;
    border: var(--bs-btn-border-width) solid #ff2626;
}


.filters-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .filter-item {
    flex: 1 1 calc(10.66% - 1rem); /* Adjust width as needed */
    
    min-width: 150px; /* Minimum width for better responsiveness */
  }
  