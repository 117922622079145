.form-container {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}


.select-container {
  width: auto;
  /* Adjust width as needed */
}


/* Custom styling for DataTables pagination */
.pagination-dark .page-item.active .page-link {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.pagination-dark .page-link {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.pagination-dark .page-link:hover {
  color: #fff !important;
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}


/* Adjust the padding or margin as necessary */
.member-table-container .dataTables_paginate {
  padding-right: 0;
  margin-top: 0.5rem;
}


/* DataTableStyleAfaa.css */

/* Ensure table cells have padding that allows for readability */
table.dataTable td {
  padding: 8px 10px; /* Adjust padding as needed */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add an ellipsis at the end of the text */
}

/* Ensure headers are styled correctly */
table.dataTable th {
  padding: 10px 10px; /* Adjust padding as needed */
}

/* Add styles for the responsive behavior */
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child {
  position: relative;
  padding-left: 30px; /* Add space for the expand button */
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  top: 10px;
  left: 4px;
  height: 16px;
  width: 16px;
  display: block;
  position: absolute;
  color: #858796; /* Adjust to match your color scheme */
  content: '▶'; /* Unicode character for the right-pointing triangle */
  font-family: 'FontAwesome';
  text-align: center;
  line-height: 16px;
}

/* Adjust this for when the row is expanded */
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: '▼'; /* Unicode character for the down-pointing triangle */
}



/* DataTableStyleAfaa.css */
/* Custom column width styles */
table.dataTable th.ServiceTypes_ID,
table.dataTable td.ServiceTypes_ID {
  min-width: 80px; /* Minimum width */
  max-width: 120px; /* Maximum width */
}
