// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/css/SwitchEventDialog.scss

// กำหนดตัวแปรสำหรับสีและพื้นหลัง
$primary-color: #04d369;


// ใช้ Mixins กับ .p-inputswitch และ .p-accordion
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $primary-color;
}

.switch-wrapper {
  position: relative;
  display: inline-block;
  width: 85px;
  height: 35px;
  margin-right: 10px;
}

.p-inputswitch {
  // width: 100%;
  // height: 100%;
  // position: absolute;
  // z-index: 0;

  &.p-inputswitch-checked .p-inputswitch-slider {
    background: $primary-color; // Set the background color when switch is checked
  }
}

.switch-label {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  pointer-events: none;
  font-weight: bold;
  font-size: 13px;
  z-index: 1;
  white-space: nowrap;
}


/* Add this CSS in your styles.css or a similar file */
.service-time-sidebar-on-top {
  z-index: 1001 !important; /* Set z-index value higher than the default */
}